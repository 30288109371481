import React, { Component } from 'react';
import axios from 'axios';
import trace from '../util/Trace';
import ComponentUtil from '../common/ComponentUtil';
import Util from '../common/utils';
import FLogViewerCloudApi from '../common/fLogViewerCloudApi';


//HealthCheck
//https://flogviewercloudapiappservice.azurewebsites.net/HealthCheck

const DEFAULT_STATE_DEBUG = {
  PersonName : "Frederico Torres",
  EMail : "fredericaltorres@gmail.com",
  Comment : "Great software",
};
const DEFAULT_STATE_RELEASE = {
  PersonName : '',
  EMail : '',
  Comment : '',
};

export default class CommentDialog extends Component {

  constructor() {

    super();
    this.state = Util.IsClientSideInDebugMode() ? DEFAULT_STATE_DEBUG : DEFAULT_STATE_RELEASE;
    if(Util.IsClientSideInDebugMode())
      trace.log(`Running in client side debug mode`);
    else
      trace.log(`Running from www.flogviewer.com`);
  }

  componentDidMount() {

      FLogViewerCloudApi.getHealthCheck();
      FLogViewerCloudApi.getAuthorizationToken();
  }

  updateState = (property, value) => {

      this.setState({ ...this.state, [property]: value }, () => {
          if(Util.IsClientSideInDebugMode())
              console.log(`state: ${JSON.stringify(this.state)}`);
      });
  }

  onFieldChange = (name, e) => {

    this.updateState(name, e.target.value);
  }

  sendComment = () => {
    FLogViewerCloudApi.postComment({
          PersonName : this.state.PersonName,
          EMail : this.state.EMail,
          Comment : this.state.Comment,          
      }).then(() => {
        window.location.href = "/";
      });
    

      
  }

  render() {

    return (
      <div>
        <table cellPadding="2" cellSpacing="2">
        <tbody>

          <tr>
            <td>Comment:</td>
            <td>
                <textarea rows="7" cols="60" id="Comment" ref="Comment" type="text" className="form-control" value={this.state.Comment} onChange={(e) => this.onFieldChange('Comment', e)} />
            </td>
          </tr>
          <tr>
            <td>Name (Optional):</td>
            <td><input id="PersonName" ref="PersonName" type="text" className="form-control" value={this.state.PersonName} onChange={(e) => this.onFieldChange('PersonName', e)} /></td>
          </tr>
          <tr>
            <td>Email (Optional):</td>
            <td><input id="EMail" ref="EMail" type="text" className="form-control" value={this.state.EMail} onChange={(e) => this.onFieldChange('EMail', e)} /></td>
          </tr>          
        </tbody>
        </table>
        <br/>
        <button className="btn btn-primary btn-sm" onClick={this.sendComment}>Send Comment</button>
      </div>
    );
  }
}
