import Tracer from './Tracer';

class Util {
	
	getParameterByName = function (name, url) {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	  }
	  
	  IsClientSideInDebugMode() {
	  
		return !(window.location.href.indexOf("localhost:3000") === -1);
	  }
}

export default new Util();
