import Tracer from './Tracer';
import axios from 'axios';
import Util from './utils';
import { isTablet, mobileModel, mobileVendor, osName, osVersion, browserName, isMobile  } from 'react-device-detect';

const LOCAL_API = false;

function get_react_device_detect_info() {
    var r = `${osName} ${osVersion}`;
    if(isMobile) {
        r = `${mobileVendor} ${mobileModel} ~ ${r}`;
    }
    else {
        r = `${browserName} ~ ${r}`;
    }
    return r;
}

class FLogViewerCloudApi {

	constructor() {
		this.AccessKey = "{4993B778-55CE-48FB-9D2E-6AE26FF4A0F9}";
		this._authorizationToken = null;
		if(LOCAL_API) {
			this.SEND_COMMENT_API   		 = "https://localhost:44324/CommentApi";
			this.HEALTH_CHECK_API   		 = "https://localhost:44324/HealthCheck";
			this.COUNT_DOWNLOAD_API 		 = "https://localhost:44324/CountDownloadApi";
			this.COUNT_VISIT_API 		 	 = "https://localhost:44324/CountVisitApi";
			this.GET_AUTHORIZATION_TOKEN_API = "https://localhost:44324/CountDownloadApi/GetAuthorization";
		}
		else {
			const hostName 					 = 'flogviewercloudapiappservice.azurewebsites.net';
			this.SEND_COMMENT_API   		 = `https://${hostName}/CommentApi`;
			this.HEALTH_CHECK_API   		 = `https://${hostName}/HealthCheck`;
			this.COUNT_DOWNLOAD_API 		 = `https://${hostName}/CountDownloadApi`;
			this.COUNT_VISIT_API 		 	 = `https://${hostName}/CountVisitApi`;
			this.GET_AUTHORIZATION_TOKEN_API = `https://${hostName}/CountDownloadApi/GetAuthorization`;
		} 
		  this.FLOGVIEWER_VERSION_API = "https://flogviewer.blob.core.windows.net/build/Version.cs";
		  this.config = {
				headers: { 'Access-Control-Allow-Origin' : '*' } // Handle CORS for our back end api
		};
	}

	_insertSecurity(data) {

		data.AccessKey = this.AccessKey;
		data.AuthorizationToken = this._authorizationToken;

		return data;
	}

	onSuccess = (response, resolve) => {

		Tracer.log(response);
		if(resolve)
			resolve(response.data);

		if(Util.IsClientSideInDebugMode()) {
			Tracer.log('sent');
		}
	}

	onError = (err, reject) => {

		Tracer.error(`postComment failed, ${err}`);
		if(Util.IsClientSideInDebugMode())  
			alert(err);
		if(reject)
			reject(err);
	}

	getAuthorizationToken = () => {
        
		Tracer.log(`getAuthorizationToken`);
		return new Promise( (resolve, reject) => {
	
			axios.get(this.GET_AUTHORIZATION_TOKEN_API+`?AccessKey=${this.AccessKey}`, this._insertSecurity(this.config))
				.then((response) => { 
					this._authorizationToken = response.data
					if(Util.IsClientSideInDebugMode())  
						console.log(`_authorizationToken ${this._authorizationToken}`);
					this.onSuccess(response, resolve); 
				})
				.catch((err) => { this.onError(err, reject); });
		});
	}

	postComment = (data) => {
		
		return new Promise( (resolve, reject) => {
		  
			if(Util.IsClientSideInDebugMode())
			  Tracer.log(`postComment ${JSON.stringify(data)}`);
  
			axios.post(this.SEND_COMMENT_API, this._insertSecurity(data), this.config)
				.then((response) => { this.onSuccess(response, resolve); })
				.catch((err) => { this.onError(err, reject); });
		});
	}

	postCountVisit = (data) => {

		data = this.IpInfo;

		if(data.city === "Hudson" || data.city === "Waltham") {
			console.log(`ME detected`);
			return Promise.resolve();
		}
		
		return new Promise( (resolve, reject) => {
		  
			if(Util.IsClientSideInDebugMode())
			  Tracer.log(`postCountVisit ${JSON.stringify(data)}`);
  
			axios.post(this.COUNT_VISIT_API, this._insertSecurity(data), this.config)
				.then((response) => { this.onSuccess(response, resolve); })
				.catch((err) => { this.onError(err, reject); });
		});
	}

	postCountDownload = (data) => {

		data = this.IpInfo;
		data = {};
		
		return new Promise( (resolve, reject) => {
		  
			if(Util.IsClientSideInDebugMode())
			  Tracer.log(`postCountDownload ${JSON.stringify(data)}`);
  
			axios.post(this.COUNT_DOWNLOAD_API, this._insertSecurity(data), this.config)
				.then((response) => { this.onSuccess(response, resolve); })
				.catch((err) => { this.onError(err, reject); });
		});
	}	

	getHealthCheck = () => {
        
		Tracer.log(`getHealthCheck`);
		return new Promise( (resolve, reject) => {
	
			axios.get(this.HEALTH_CHECK_API, this._insertSecurity(this.config))
				.then((response) => { this.onSuccess(response, resolve); })
				.catch((err) => { this.onError(err, reject); });
		});
	}

	getFLogViewerLastVersion = () => {

		var config = {
			headers: {'x-ms-meta-data': '*'}
		};
		return new Promise( (resolve, reject) => {
			
			axios.get(this.FLOGVIEWER_VERSION_API, config)
				.then((response) => {
					const regex = /AssemblyFileVersion\("?(.*)"\)/;
					const extract = response.data.match(regex);
					if(extract.length === 2) {
						const fLogViewerVersion = extract[1];
						this.onSuccess({ data: fLogViewerVersion }, resolve);
					}
				})
				.catch((err) => { this.onError(err, reject); });
		});
	}	  

	ipDetected(ipInfo) {
        // {"ip":"96.252.90.52","hostname":"pool-96-252-90-52.bstnma.fios.verizon.net","city":"Hudson",
		// "region":"Massachusetts","country":"US","loc":"42.3918,-71.5662","org":"AS701 MCI Communications Services, Inc. d/b/a Verizon Business","postal":"01749","timezone":"America/New_York"}
		
		this.IpInfo = ipInfo;
        
		ipInfo.deviceInfo = get_react_device_detect_info();
		ipInfo.localDate = new Date().toLocaleString();

		console.log(JSON.stringify(ipInfo));
        
        const ipInfoString = `WebSite:${ipInfo.localDate} ip:${ipInfo.ip} city:${ipInfo.city} country:${ipInfo.country} postal:${ipInfo.postal} deviceInfo:${ipInfo.deviceInfo} org:${ipInfo.org}`;
        console.log(ipInfoString);

        if(ipInfo.city === "Hudson" || ipInfo.city === "Waltham") {
            console.warn(`(No SMS) probably me`);
        }
        else {
            // this.sendSMS(ipInfoString);
            // ComponentUtil.forceRefresh(this, { shouldSMSNotify: true, userIp: ipInfo.ip});
            // this.props.onShouldSMSNotify(); // Notify the App that we can send sms
        }
    }

	fetchIpInfo = () => {
        fetch('https://ipinfo.io?token=87272e954a7c7c')
        .then((response) => { return response.json(); })
        .then((ipInfo) => { this.ipDetected(ipInfo); })
        .catch((err) => {
            console.log(`error:${err} -- ${JSON.stringify(err)}`);
        });
    }
}

export default new FLogViewerCloudApi();
