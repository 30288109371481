import React from 'react';
import logo from './logo.svg';
import Util from './common/utils';
import FLogViewer from './Components/fLogViewer';
import CommentDialog from './Components/commentDialog';
import PrivacyPolicy from './Components/PrivacyPolicy';
import PeopleComments from './Components/peopleComments';
import ProgrammingModel from './Components/programmingModel';
import CopyRight from './Components/copyRight';


import { // https://reacttraining.com/react-router/web/guides/quick-start
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';

// https://reacttraining.com/react-router/web/guides/quick-start



/*
  UI https://www.flogviewer.com/?c=1
  https://flogviewercloudapiappservice.azurewebsites.net/CommentApi
  POST
  {"PersonName":"fred 2021","EMail":"fredericaltorres@gmail.com","Comment":"bof","AccessKey":"{4993B778-55CE-48FB-9D2E-6AE26FF4A0F9}","AuthorizationToken":"e6e5599d-575e-4287-bf3e-22a272eae31e"}
  curl.exe -X POST -H "Content-type: application/json" -d "{`"`"PersonName`"`":`"`"fred CURL`"`",`"`"EMail`"`":`"`"fredericaltorres@gmail.com`"`",`"`"Comment`"`":`"`"bof`"`",`"`"AccessKey`"`":`"`"{4993B778-55CE-48FB-9D2E-6AE26FF4A0F9}`"`",`"`"AuthorizationToken`"`":`"`"e6e5599d-575e-4287-bf3e-22a272eae31e`"`"}"  https://flogviewercloudapiappservice.azurewebsites.net/commentapi

  flogviewerapimanagement
  https://portal.azure.com/#@fredericaltorreslive.onmicrosoft.com/resource/subscriptions/57646804-986c-47e8-af66-a3abec32e52a/resourceGroups/flogviewer/providers/Microsoft.ApiManagement/service/flogviewerapimanagement/overview
*/

function App() {
  
  let componentToRender = <FLogViewer/>;
  if(Util.getParameterByName("c") === "1")
    componentToRender = <CommentDialog />;
  if(Util.getParameterByName("pc") === "1")    // https://www.flogviewer.com/?pc=1
    componentToRender = <PeopleComments />;
  if(Util.getParameterByName("pm") === "1")    // https://www.flogviewer.com/?pm=1
    componentToRender = <ProgrammingModel />;
  if(Util.getParameterByName("privacy") === "1")   // https://www.flogviewer.com/?privacy=1 
    componentToRender = <PrivacyPolicy />;

    componentToRender = <div>
        {componentToRender}
        <CopyRight/>
    </div>    

  return (
    <Router>
      <div>

            <nav id="main-nav-bar" className="navbar navbar-expand-lg navbar-light bg-light ">
              <a className="navbar-brand" href="#">
                <i><b>fLogViewer v 2</b></i>
                </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item ">  {/* active */}
                    <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/?pm=1">Programming Model</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/?c=1">Enter Comment</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/?pc=1">People's Comments</a>
                  </li>
                </ul>
              </div>
            </nav>

        {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
        {/* <Switch>
          <Route path="/c=1">
            <CommentDialog />
          </Route>
          <Route path="/">
            <FLogViewer/>
          </Route>
        </Switch> */}

          {componentToRender}
        
      </div>
    </Router>
  );
}

export default App;
