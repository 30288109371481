import TypeUtil from './TypeUtil';
import Tracer from './Tracer';

export const ESCAPE_KEY = 27;
export const ENTER_KEY  = 13;

class ComponentUtil  {

	static forceRefresh (reactComponent, statePropertiesToUpdate, callBack = null) {

		const state = reactComponent.state;
		const timeStamp = new Date().getTime();
		let newState = null;
		if(statePropertiesToUpdate) 
			newState = { ...state, ...statePropertiesToUpdate, timeStamp }
		else
			newState = { ...state, timeStamp }
		// console.log(JSON.stringify(newState));
		reactComponent.setState(newState, callBack ? callBack : undefined);
	}
	static setIsLoading(reactComponent, state) {
		
		console.log(`============ ${typeof(jest)}`);
		Tracer.log(`setIsLoading: ${state}`); // TODO: Do not log when runnig 
		ComponentUtil.forceRefresh(reactComponent, { isLoading: state });
	}
	static executeAsBusy(reactComponent, func, onDoneFunc = null) {
		
		if(!TypeUtil.isFunction(func))
			TypeUtil.throwInvalidParameterType('func', 'function');

		ComponentUtil.setIsLoading(reactComponent, true);

		return new Promise((resolve, reject) => {

			const r = func();
			if(r && TypeUtil.isPromise(r)) {
				return r.then(() => {
					ComponentUtil.setIsLoading(reactComponent, false);
					if(onDoneFunc) {
						onDoneFunc();
					}
					resolve();
				});
			} 
			else {
				ComponentUtil.setIsLoading(reactComponent, false);
			}
			resolve();
		});
	}	
	static getNewUniqueId() {
		
		return Math.random().toString(16).substr(2, 16);
	}
}

export default ComponentUtil;
