import React, { Component } from 'react';
import axios from 'axios';
import trace from '../util/Trace';
import ComponentUtil from '../common/ComponentUtil';


export default class PeopleComments extends Component {

  constructor() {

    super();
    this.state = {
    }
  }

  componentDidMount() {

  }

  render() {

    return (
      <div>
            <p>
            <h3>What people said about fLogViewer v 1.x</h3>
                  <hr/>
                  <p>
                        fLogViewer was part of the Scott Hanselman's 2005 and 2006 Ultimate Developer and Power Users Tool List. 
                        Scott's comment: <i>Great freeware highlighting log viewer for large log files. </i>
                        &nbsp;
                        <a target="top" href="https://web.archive.org/web/20070210044646/http://www.hanselman.com/blog/ScottHanselmans2006UltimateDeveloperAndPowerUsersToolListForWindows.aspx">2006</a>
                        &nbsp;
                        <a target="top" href="https://web.archive.org/web/20070228143724/http://www.hanselman.com/blog/ScottHanselmans2005UltimateDeveloperAndPowerUsersToolList.aspx">2005</a>
                  </p>
                  <hr/>
                  <p>
                      ...But those of us stuck on Windows would usually prefer some sort of GUI tool.
                      fLogViewew fits into this niche nicely. It's a MDI program wirtten in VB6, but seems to be plenty fast.
                      Features include color-coding (done by looking for keywords in the lines of the log), the smarts to monitor log files and refresh them on the fly (even with IIS's caching scheme), and a variety of filtering and export options...
                      Mike Gunderloy's NewsLettter.
                      &nbsp;
                      <a target="top" href="https://web.archive.org/web/20060813190431/https://mcpmag.com/newsletter/article.asp?EditorialsID=157">Mike Gunderloy's NewsLettter.</a>
                  </p>
                  <hr/>
                  <p>
                      Thanks for developing flogviewer, it is a great help for me. If it wouldn´t be freeware I even would pay for it. ;-)&nbsp;
                      <a target="top" href="/">Jens. M.</a>
                  </p>                  

                  <hr/>
                  <p>
                      LogViewer - Great freeware highlighting log viewer for large log files. &nbsp;
                      <a target="top" href="https://web.archive.org/web/20120129220926/https://mostlylucid.net/archive/0001/01/01/possibly-the-ultimate-developer-tools-list.aspx">Scott Galloway's Possibly the ultimate developer tools list.</a>
                  </p>

                  <hr/>
                  <p>
                      Je m'étais débrouillé avec fLogViewer (freeware:http://www.flogviewer.com). Bon, il faut définir soi-même les filtres mais c'est un outil très puissant...&nbsp;
                      <a target="top" href="https://web.archive.org/web/20080327054039/http://gotoandplay.ca/archives/2004/04/29/trace-dans-un-fichier-text-suite.html">Unknown</a>
                  </p>

                  <hr/>
                  <p>
                      Hej! Jeg kan stærkt anbefale dette freeware program til at læse diverse logfiler med.
                      Det kan bla. opdatere logfilerne dynamisk mens de er åbne, hvilket er super til f.eks. troubleshooting osv. :)
                      ... Hent programmet fLogViewer her: http://www.flogviewer.com
                      <br/>
                      <a target="top" href="https://web.archive.org/web/20070319052851/http://www.it-experts.dk/forum/topic.asp?TOPIC_ID=37">Peter Schmidt</a> - Since we do not speak Danish at fLogViewer.com, we hope this comment is good.
                  </p>

                  <hr/>
                  <p>
                      I've posted about baretail previously as a tail program for Windows, but now I see there is a similar tool with some more functionality to it. fLogViewer picks up and runs with the "Windows way" by taking a simple tool and putting more and more features onto it (note: Yes, I am fairly sarcastic there, but the features are appreciated nonetheless!). I kinda like this tool, although the necessity of an install and the way it uses some older system files than what I have on my XP system anyway are detractors to replacing baretail with fLogViewer.
                      <br/>
                      <a target="top" href="https://web.archive.org/web/20080616125412/http://www.terminal23.net/2007/05/flogviewer.html">Michael L. Dickey</a>
                  </p>                  

                  <hr/>
                  <p>
                      We are developing a consumer device with an embedded ARM microcontroller.
                      Our prototype system has a very simple ASCII serial trace output. In order to confirm reliability of the product, it must be run for long periods and trace logs taken for subsequent analysis. I have found that flogviewer is ideal not only for this, but also for real time analysis of the trace output. From experience, most terminal emulators have problems with large log files, in particular if one attempts to look at the trace history. However, by viewing the trace log file with flogviewer, the problems are significantly reduced.
                      Furthermore, filters can be used to look at particular traces in real time.
                      All in all flogviewer is an excellent tool for our purposes.
                      Hope that´s OK and many thanks for a great tool.
                      Cheers.
                      <a target="top" href="/">Lisa Kingscott</a>
                  </p>

                  <hr/>
                  <p>
                      A Tail with more power in its tip than the original has in 'full length'. Powerful filter options, together with extensive highlighting options and scripting support will make sure you will spot just those lines in your logs that matter to you. Even if the structure of your logfiles varies.
                      <a target="top" href="/">Ruud, NL</a>
                  </p>

                  <hr/>
                  <p>
                      I stumbled over flogviewer a few years ago on a post by Scott Hanselman.
                      I was looking for a freeware logviewer which would give me the possibility to
                      adapt it's color-highlighting to my differing needs.
                      Flogviewer is an awesome piece of software which helped me a lot in my daily routines and
                      in tracking down probs on my servers.

                      Great job Fred. Thanks a lot for your great work.
                      <a target="top" href="/">Ralf R, Germany.</a>
                  </p>


            </p>
      </div>
    );
  }
}


