import React, { Component } from 'react';
import axios from 'axios';
import trace from '../util/Trace';
import ComponentUtil from '../common/ComponentUtil';


export default class programmingModel extends Component {

  constructor() {

    super();
    this.state = {
    }
  }

  componentDidMount() {

  }

  render() {

    return (
      <div>
          <p>
            <h3>Programming model</h3>
            Two type of .NET plug-in can be developed for fLogViewer
            <ul>
              <li>
                  Text line viewer plug-in: When the plug-in is selected, 
                  and the user hit enter the plug-in is opened and in charge of displaying a different representation of the current line.
              </li>
              <li>Text processor plug-in: When the user right-click and select the plug-in, it is called for each line selected. 
                The plug-in is in charge to execute a process based on each line and to return a final result in a file and the method Close() is called</li>
            </ul>
            <b><i>Code sample coming soon...</i></b>
            </p>            
      </div>
    );
  }
}



/*
http://www.hanselman.com/blog/ScottHanselmans2005UltimateDeveloperAndPowerUsersToolList.aspx
https://web.archive.org/web/20070228143724/http://www.hanselman.com/blog/ScottHanselmans2005UltimateDeveloperAndPowerUsersToolList.aspx

http://www.hanselman.com/blog/ScottHanselmans2006UltimateDeveloperAndPowerUsersToolListForWindows.aspx
https://web.archive.org/web/20070210044646/http://www.hanselman.com/blog/ScottHanselmans2006UltimateDeveloperAndPowerUsersToolListForWindows.aspx

Incisif.net web site
https://web.archive.org/web/20070217191223/http://www.flogviewer.com/

Old fLogViewer web site

*/