import React, { Component } from 'react';
import axios from 'axios';
import trace from '../util/Trace';
import ComponentUtil from '../common/ComponentUtil';
import Util from '../common/utils';
import FLogViewerCloudApi from '../common/fLogViewerCloudApi';

const DEFAULT_STATE = {};

export default class PrivacyPolicy extends Component {

  constructor() {

    super();
    this.state = DEFAULT_STATE;
  }

  render() {

    return (
      <div>
          <h1>fLogViewer usage - privacy policy</h1>
              <h2>Telemetry</h2> 
              <p>
              fLogViewer does <b>not</b> collect or transmit personnel information from within the app.
              </p>
              
              For usage analysis, fLogViewer communicates to the cloud (Azure AppInsight)
              <ul>
                <li>When the application start and shutdown</li>
                <li>Error displayed to user</li>
                <li>The <b>type</b> of file viewed (Files, Aggregated File, URL, Transact-SQL statment, Directory, Azure Storage)</li>
              </ul>
              <ul>
                <li>When you install fLogViewer a unique random Guid is assigned to the installation called the InstallID. 
                  The InstallID is sent to Azure AppInsight.</li>
                <li>Information related to your IP and your machine name are <b>not</b> collected.</li>
                <li>All your configuration information are <b>not</b> collected.</li>
              </ul>
              <h3>Storage</h3>
              <p>
                <ul>
                  <li>The information is stored in Microsoft Azure servce AppInsight and can only be accessed by fLogViewer's creator.</li>
                  <li>The information is deleted after 90 days.</li>
                </ul>
              </p>
      </div>
    );
  }
}
