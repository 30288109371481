import React, { Component } from 'react';
import axios from 'axios';
import trace from '../util/Trace';
import ComponentUtil from '../common/ComponentUtil';


export default class copyRight extends Component {

  constructor() {

    super();
    this.state = {
    }
  }

  componentDidMount() {

  }

  render() {

    return (
      <div>
          <hr/>
            <p>
              <center>
              <b>
                <i>
                    fLogViewer - &#xa9; Frederic Torres 2006, 2023
                </i>
              </b>
              </center>
          </p>

      </div>
    );
  }
}
