////////////////////////////////////////////////////////
///
/// http://devtoolsecrets.com/secret/console-log-info-warn-error.html
///
class Trace { //  extends Component 

    traceOn = true;

    trace(msg) {

        console.log("%c INFO: "+msg, "color:blue; background-color:white");
    }
    log(msg) {
        if(this.traceOn) {
            this.trace(msg);
        }
    }
    warning(msg) {

        console.warn("%c WARNING: "+msg+" ", "color:yellow; background-color:gray");
    }
    error(msg) {

        console.error("%c ERROR: "+msg, "color:red");
    }
}

let trace = new Trace();
export default trace;
